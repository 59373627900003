.cart-container {
  padding: 0 min(2vw, 30px);
}

.empty-cart-cls {
  margin: 50% 0;
}

.empty-cart-cls > div > span {
  font-size: 30px;
}

.total-money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: gray;
  font-style: italic;
  padding: 10px;
}
