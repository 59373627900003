.jvs-chat-container i {
  font-size: 38px;
  margin: auto;
  color: white;
}

.consulation-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: -20px;
  right: 60px;
  z-index: 1;
  position: absolute;
  border: 1px solid rgb(231, 228, 228);
}

.consulation-tooltiptext:hover {
  visibility: visible;
}

.jvs-chat-container {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 24px;
  bottom: 90px;
  background-color: black;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
}
.jvs-chat-container > img {
  width: 46px;
  margin: auto;
}

/*
.facebook-chat-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 24px;
  bottom: 24px;
  background-color: #0a7cff;
  border-radius: 50%;
  z-index: 300;
}

.facebook-chat-container svg {
  margin: auto;
} */

.zalo-chat-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 1vw;
  bottom: 40px;
  background-color: #135aab;
  border-radius: 50%;
  z-index: 300;
  opacity: 0.9;
  cursor: pointer;
}
.zalo-chat-container:hover {
  opacity: 1;
}
.zalo-chat-container > div {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url(/src/assets/img/zalo_2021.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 65%;
  margin: auto;
}
