.products-content {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  max-height: 80vh;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.products-content.vetically {
  flex-wrap: wrap;
  margin: 0 20px;
}

.products-content.horizontaly {
  flex-wrap: nowrap;
}

.products-content.vetically::-webkit-scrollbar {
  display: none;
}
