.product-page-container {
  background-color: white;
  margin-bottom: 30px;
  max-height: 80vh;
}

.product-page-banner {
  object-fit: cover;
  width: 100vw;
  max-height: 500px;
}
