.library-page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.libraries {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: auto;
  width: fit-content;
  max-width: 80vw;
}

.library-item {
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  max-width: 120px;
  max-height: 120px;
  min-width: 100px;
  min-height: 100px;
  width: 100%;
  background: #000000;
  text-align: left;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
}

.library-item:hover {
  border: solid gray 1px;
  border-radius: 5px;
}

.library-item.active {
  border: solid gray 1px;
  border-radius: 5px;
}

.library-item img {
  max-width: 100%;
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.library-item figcaption {
  position: absolute;
  bottom: 15%;
  left: 20%;
  margin: auto;
  width: 60%;
  height: 70%;
  background-color: #fdfdfd5e;
  border-right: solid white 6px;
  visibility: hidden;
  overflow: hidden;
}

.library-item:hover figcaption {
  animation-name: bounce;
  animation-duration: 4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  visibility: visible;
}

@keyframes bounce {
  0% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}

.library-item figcaption h5 {
  word-spacing: -0.15em;
  font-weight: 200;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
  text-transform: uppercase;
  color: red;
  /* visibility: hidden; */
  margin: auto;
}

.library-item:hover figcaption h5 {
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-name: bounce-title;
}

@keyframes bounce-title {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.library-image-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.library-image-child {
  margin: 10px;
  overflow: hidden;
}

@media screen and (min-width: 1240px) {
  .library-image-child {
    width: calc((90vw - 20px) / 6 - 22px);
  }
}

@media screen and (min-width: 700px) and (max-width: 1239px) {
  .library-image-child {
    width: calc((90vw - 20px) / 4 - 22px);
  }
}

@media screen and (min-width: 400px) and (max-width: 699px) {
  .library-image-child {
    width: calc((90vw - 20px) / 2 - 22px);
  }
}

@media screen and (max-width: 399px) {
  .library-image-child {
    width: calc(90vw - 42px);
  }
}

.library-image-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
  border: 1px solid lightgray;
  cursor: pointer;
  border-radius: 10px;
}

.library-image-title {
  font-size: large;
  font-weight: 600;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
