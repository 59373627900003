.product-detail-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/*top*/
.product-detail-top-child {
  display: flex;
  flex-direction: column;
}
.product-detail-top-child-thumb-img > div {
  background-color: white;
  box-shadow: 3px 3px 3px lightgray, -3px 3px 3px lightgray, 3px -3px 3px lightgray,
    -3px -3px 3px lightgray;
  cursor: pointer;
  transition: 0.5s;
}
.product-detail-top-child-thumb-img > div:hover {
  transform: scale(1.1);
}
.product-detail-top-child-thumb-img img {
  width: 100%;
  object-fit: contain;
}

.product-detail-price {
  font-size: x-large;
  font-weight: 500;
  text-shadow: 4px 4px 3px lightgray;
  color: gray;
}
.product-detail-intro {
  font-size: 14px;
  font-weight: 600;
}
/*option container*/
.option-container > div {
  border: 1px solid #2b80dd;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}
.option-container > div:hover {
  color: #2b80dd;
}
.option-container > div > div:nth-child(1) {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.option-container > div > div:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: red;
}
.product-detail-checkbox {
  width: 15px !important;
  height: 15px !important;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #2b80dd;
  box-shadow: none;
  border-radius: 50%;
  cursor: pointer;
}
.product-detail-checkbox.checked {
  background-color: #2b80dd;
}

/*promotion*/
.product-detail-promotion {
  position: relative;
  font-size: 15px;
  font-weight: 475;
  border-radius: 5px;
  border: 1px solid #16c60c;
}
.box-promotion {
  font-size: 16px;
  font-weight: 500;
  top: 0;
  left: 45%;
  width: 231px;
  padding: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #16c60c;
  position: absolute;
  z-index: inherit;
}

/*add to cart*/
.product-detail-add-to-cart-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btn-add-to-cart {
  margin: 10px;
  border-radius: 5px;
  color: white;
  padding: 8px 2vw;
  background-color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.25s;
}
.btn-add-to-cart:active {
  transform: translateY(3px);
}
.btn-add-to-cart > div:nth-child(1) {
  font-size: 19px;
}
.btn-add-to-cart > div:nth-child(2) {
  font-size: 13px;
}

@media all and (min-width: 800px) {
  .product-detail-info {
    width: 44vw;
    padding-left: 5vw;
  }
}

@media all and (max-width: 799px) {
  .product-detail-info {
    width: 90vw;
  }
}

.product-name {
  font-size: xx-large;
}

.option-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
}

.option-value {
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  border: 1px solid lightgray;
  margin: 10px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.option-value label {
  min-width: 30px;
  padding: 5px;
  margin: auto 5px;
}

.option-value img {
  width: 80px;
  height: 100px;
  object-fit: cover;
}

.option-active {
  border: 1px solid #2b80dd;
}

/*Commit*/
.product-detail-commit-container {
  border: 1px solid black;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.product-detail-commit-title {
  font-weight: 625;
  color: black;
  margin-bottom: 10px;
}
.product-detail-commit-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  font-weight: 500;
  height: 20px;
  background-image: url(/src/assets/img/icon_star_list2.png);
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-bottom: 2px;
}

/*address*/
.product-detail-address-container {
  border: 1px solid #2b80dd;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.product-detail-address-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 500;
  height: 20px;
  padding-left: 10px;
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-bottom: 2px;
}
.product-detail-address-value > i {
  color: #2b80dd;
  margin-right: 10px;
}

.related-products-title {
  margin-top: 10vh;
  font-size: large;
  font-weight: 600;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
