.destination-container {
  display: flex;
  flex-direction: row;
}

.list-destination {
  max-height: 80vh;
}

.destination-item {
  max-width: 100vw;
  margin: 10px;
  box-shadow: lightgrey 0px 0px 6px 1px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.all-destination {
  display: flex;
  justify-content: center;
}

.destination-item-title {
  font-size: larger;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3.2ex;
  max-height: 6.4ex;
}

.destination-item-des {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3.2ex;
  max-height: 19.2ex;
}

.destination-item-content {
  padding-left: 20px;
  overflow: hidden;
}

.destination-avatar {
  width: 280px;
  height: 210px;
  object-fit: cover;
  max-width: 90vw;
}

@media screen and (min-width: 800px) {
  .destination-item-content {
    width: calc(100% - 280px);
  }
}

@media screen and (max-width: 799px) {
  .destination-item-content {
    width: 100%;
    margin-top: 10px;
  }
}
