.news-container {
  background-color: white;
}
.news-child {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.news-child > div {
  cursor: pointer;
  background-color: #f5f5f5;
}

/*title*/
.news-title {
  font-size: calc((100vw / 1366) * 4 + 14px);
  font-weight: 650;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.news-title > div {
  cursor: pointer;
}
.news-title > div:hover {
  color: blue;
}

/*item*/
.news-item > div:nth-child(2) {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.news-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-item-title {
  font-size: 20px;
  font-weight: 500;
}
.news-item-des {
  font-size: 17px;
}
@media all and (max-width: 991px) {
  .news-item > div:nth-child(2) {
    align-items: center;
    height: fit-content !important;
  }
  .news-item-value > div:nth-child(1) {
    height: 150px !important;
  }
  .news-item-title {
    font-size: 15px;
    font-weight: 600;
  }
  .news-item-des {
    font-size: 14px;
  }
  .news-item-value {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start !important;
  }
  .news-item-value > div {
    height: 100%;
  }
  .news-item-value img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
  }
}
.news-value {
  width: 100%;
  overflow: hidden;
}
.news-value > div {
  background-color: #f5f5f5;
}

/*comment*/
.news-all-comment {
  background-color: white !important;
}
.news-comment-title {
  font-size: 20px;
  font-weight: 650;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
.news-comment-value {
  background-color: white;
}
.add-comment-new-container > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add-comment-new-container textarea {
  padding: 2px 15px;
  background-color: #f5f5f5;
  border-radius: 15px;
  resize: none;
}
@media all and (max-width: 991px) {
  .add-comment-new-container textarea {
    border-radius: 10px !important;
    height: 42px !important;
    font-size: 13px !important;
    padding: 2px 10px;
  }
}
.add-comment-new-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
@media all and (max-width: 991px) {
  .add-comment-new-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.add-comment-new-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.add-comment-new-btn-login {
  color: #2b80dd;
  cursor: pointer;
}
