.banner-home-container {
  display: flex;
  flex-direction: row;
}

.home-info {
  margin: 5px 0;
}

.realm-description {
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 30px 20px;
  color: black;
  background-color: white;
  text-align: center;
}

.home-news {
  margin-top: 20px;
}
