.elm-search-details-des {
  display: block;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.cart-header {
  display: flex;
  flex-direction: row;
  font-size: larger;
  font-weight: 500;
  margin: auto 0;
  justify-content: space-between;
  padding: 15px 25px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
}

.cart-header-middle {
  vertical-align: middle;
  margin: auto 0 auto 10px;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 2;
  box-shadow: grey 0px 0px 6px 1px;
}

.header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}
.logo {
  height: 42px;
  cursor: pointer;
  margin: 3px;
}
.logo img {
  height: 100%;
  object-fit: contain;
}

/*Search*/
.search {
  height: 30px;
  justify-content: center;
  display: flex;
  flex: 1;
  margin-right: 5px;
}
.search input {
  border-radius: 5px;
  width: 100%;
  background-color: white;
  border: 1px solid gray;
}
.search input:focus {
  border: 1px solid black;
}
.search .elm-search {
  width: 100%;
  overflow-y: scroll;
  top: 72px;
  left: 72px;
  position: fixed;
  max-width: calc(100vw - 75px);
  max-height: 80vh;
  box-shadow: lightgrey 0px 0px 6px 1px;
  background-color: gray;
}
.elm-search-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 75px;
  cursor: pointer;
}
.elm-search-child img {
  margin-right: 10px;
  height: 75px;
  width: 75px;
  object-fit: cover;
  transition: 0.5s;
}
.elm-search-details {
  font-size: 14px;
  display: block;
  overflow: hidden;
  height: 100%;
}
.elm-search-child:hover .elm-search-details {
  color: #2b80dd;
}
.elm-search-child:hover img {
  transform: scale(1.2);
}
.elm-search-price {
  color: red;
}

/*hotline*/
.hotline {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hotline > div:nth-child(1) {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
}
.hotline > div:nth-child(2),
.hotline > div:nth-child(3) {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 5px;
}
.account-container img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}
.account-container > i {
  font-size: 28px;
  color: #c03;
  font-weight: 900;
  cursor: pointer;
}
.btn-close-account-popup {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-close-account-popup > i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  width: 100%;
  height: 100%;
  color: #2b80dd;
}

/*acount-popup*/
.account-popup {
  position: fixed;
  top: 0;
  right: -200vw;
  width: 200vw;
  height: 100vh;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 1px 1px 1px lightgray, -1px 1px 1px lightgray;
  transition: 0.5s;
}
.account-popup.show {
  right: 0;
}
.account-popup > div {
  background-color: white;
  padding: 5px;
  width: 300px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}
.btn-close {
  display: flex !important;
  justify-content: start !important;
  width: fit-content !important;
  font-size: 30px !important;
  color: #2b80dd !important;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.log {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  width: 100%;
  padding: 5px;
  padding-left: 60px;
  cursor: pointer;
  border-radius: 5px;
}
.log:hover {
  background-color: lightgray;
  color: #2b80dd;
}

.header-icon {
  position: relative;
  position: relative;
  cursor: pointer;
  color: #c03;
  margin-left: 5px;
}
.header-icon span {
  font-size: 24px;
  transition: 0.5s;
  vertical-align: middle;
}
.header-icon:hover span {
  color: gray !important;
}

.badge {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  width: 16px;
  height: 16px;
  font-weight: 500;
  color: white;
  background-color: black;
  border-radius: 50%;
  top: -6px;
  right: 0px;
}
/*end of header top*/

/*header bot*/
.header-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
}
/* .elm-realm-child {
  width: fit-content;
  text-align: center;
  position: relative;
  font-size: 1vw;
  font-weight: 500;
  color: white;
  cursor: pointer;
  height: 2.2vw;
  flex: 1;
} */
.elm-category-child {
  display: none;
  top: 2.2vw;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  background-color: #4892e0;
  color: black;
  font-weight: 500;
  width: 100%;
  color: white;
}
.elm-category-child > div {
  padding: 5px 0px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
}

/*nav pc*/
.nav-pc-header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #c03;
  padding: 0 10px;
}
.nav-pc-header-top_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nav-pc-header-top_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-pc-header-top-child:hover {
  color: gray;
}

.nav-pc-header-top-child {
  padding: 2px 8px;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  margin: 0 2px;
  cursor: pointer;
  border: solid 2px rgb(244, 244, 244);
}
.flag-icon:hover {
  opacity: 0.8 !important;
}
.flag-icon.deactive {
  opacity: 0.5;
}

.flag-icon.active {
  opacity: 1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 252, 14, 0.6);
}

.flag-icon-gb {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.0.0/flags/4x3/gb.svg);
}
.flag-icon-vn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.0.0/flags/4x3/vn.svg);
}
.flag-icon-jp {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.0.0/flags/4x3/jp.svg);
}

/* .header-menu {
  margin: 0 1px 0 0;
  background-image: linear-gradient(rgb(126, 125, 125), lightgray);
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 10px;
  cursor: pointer;
} */

.header-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header-menu li {
  float: left;
  border-radius: 5px;
  margin: 0 1px 0 0;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  background-color: #c03;
}

.header-menu li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
}

.header-menu li:hover,
.dropdown:hover .dropbtn {
  background-color: rgb(32, 32, 32);
}

.header-menu li.dropdown {
  display: inline-block;
}

.header-menu .dropdown-content {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header-menu .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.header-menu .dropdown-content a:hover {
  background-color: #f1f1f1;
  color: black;
}

.header-menu .dropdown:hover .dropdown-content {
  display: block;
}

.vertical-menu-clear {
  border-bottom: solid lightgrey 1px;
  width: 100%;
  padding: 10px;
}
