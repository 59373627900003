.partner-image-container {
  background-color: white;
}

.partner-image-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
}

.partner-image-child {
  margin: 10px;
  overflow: hidden;
}

@media screen and (min-width: 1240px) {
  .partner-image-child {
    width: calc((100vw - 40px) / 14 - 22px);
  }
}

@media screen and (min-width: 700px) and (max-width: 1239px) {
  .partner-image-child {
    width: calc((100vw - 40px) / 10 - 22px);
  }
}

@media screen and (max-width: 699px) {
  .partner-image-child {
    width: calc((100vw - 40px) / 6 - 22px);
  }
}

.partner-image-child img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.partner-image-title {
  font-size: large;
  font-weight: 600;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
