.feature-tab {
  margin: 20px;
  border-bottom: solid rgb(219, 215, 215) 1px;
}

.feature-tab-item {
  padding: 10px;
  font-size: larger;
  margin-right: 1px;
  background-color: rgba(204, 0, 51, 0.8);
  color: white;
}

.feature-tab-item.active {
  background-color: #c03;
  color: white;
}

.feature-tab-item:hover {
  background-color: rgb(128, 126, 126);
}
