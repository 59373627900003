.vertical-menu-item {
  background-color: lightgrey;
  margin-bottom: 1px;
  cursor: pointer;
}

.vertical-menu-item span {
  margin: auto 0;
  vertical-align: middle;
  margin: 10px;
}

.vertical-menu-item .dropdown-content {
  background-color: lightgray;
  padding-left: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.vertical-menu-item.active {
  background-color: gray;
}

.vertical-menu-item .dropdown-content a {
  padding: 10px;
  margin-bottom: 1px;
  background-color: white;
}

.vertical-menu-title {
  display: flex;
  flex-direction: row;
}

.vertical-menu-href {
  flex: auto;
}
